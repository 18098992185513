import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import { store } from './redux/store/store';
import 'semantic-ui-less/semantic.less';
import App from './components/App';
import { ModalProvider } from './contextApi/ModalProvider';
import { ThemeProvider } from './contextApi/ThemeProvider';

import './theme/tailwind-prefixed.css';
import './theme/all.css';
import TeamsLogin from './components/Login/TeamsLogin';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: window.location.hostname,
    ignoreErrors: [
      'Non-Error promise rejection captured with keys: [object has no keys]',
      "Cannot read property 'setRecyclingEnabled' of null",
      "null is not an object (evaluating 'e.createBuffer')",
      "null is not an object (evaluating 'this.workspace.toolbox_.getSelectedCategoryId')",
      "null is not an object (evaluating 'e.workspace.getFlyout().setRecyclingEnabled')",
      "Cannot read property 'getWorkspace' of null",
      'this.workspace.toolbox_ is null',
      "Unable to get property 'getSelectedCategoryId' of undefined or null reference",
      'Non-Error promise rejection captured with keys: [object has no keys]',
      'Tried to glow stack on block that does not exist.',
      'IndexSizeError',
    ],
  });
}

const isTeamsCallback = window.location.pathname.includes('/teams/oauth');

render(
  <Provider store={store}>
    <ModalProvider>
      <ThemeProvider>
      <Router>{isTeamsCallback ? <TeamsLogin /> : <App />}</Router>

      </ThemeProvider>
    </ModalProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
